<template>
  <Avatar class="bg-black-alpha-90 text-primary" size="xlarge" shape="circle">
    {{
      $store.state.curUser ? createName($store.state.curUser.user_name) : "AA"
    }}
  </Avatar>
  <div class="mt-4 grid grid-nogutter border-top-1 surface-border pt-2">
    <div class="col-12 md:col-6 p-3">
      <div class="text-500 font-medium mb-2">Ф.И.О.</div>
      <div class="text-900">
        {{
          $store.state.curUser && $store.state.curUser.user_name != ""
            ? $store.state.curUser.user_name
            : emptyData
        }}
      </div>
    </div>
    <div class="col-12 md:col-6 p-3">
      <div class="text-500 font-medium mb-2">Email</div>
      <div class="text-900">
        {{
          $store.state.curUser && $store.state.curUser.email != ""
            ? $store.state.curUser.email
            : emptyData
        }}
      </div>
    </div>
    <div class="col-12 md:col-6 p-3">
      <div class="text-500 font-medium mb-2">Роль</div>
      <div class="text-900">
        {{
          $store.state.curUser
            ? config.ROLE[$store.state.curUser.role].name
            : ""
        }}
      </div>
    </div>
    <div class="col-12 md:col-6 p-3">
      <div class="text-500 font-medium mb-2">Телефон</div>
      <div class="text-900">
        {{
          $store.state.curUser && $store.state.curUser.phone != ""
            ? $store.state.curUser.phone
            : emptyData
        }}
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/configs";
import { createProfileName } from "@/handlers/createProfileName";
export default {
  data() {
    return {
      config: config,
      profiles: null,
      companies: [],
      loading: true,
      emptyData: "Не указано",
    };
  },
  methods: {
    createName(value) {
      return createProfileName(value);
    },
  },
};
</script>

<style>
</style>