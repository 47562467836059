import api from "@/api/index";
import store from "@/store";
import { mapActions } from "vuex";
import { setEmoji } from "@/handlers/emoji";

export function getProfile(that) {
  api
    .get("user/get")
    .then((r) => {
      if (
        !store.state.curUser ||
        store.state.curUser.phone != r.data.phone ||
        JSON.stringify(r.data) !== JSON.stringify(store.state.curUser)
      ) {
        that.profiles = r.data;
        store.commit("setUser", r.data);
        mapActions(["getCompanyList"]);
        if (store.state.company.companyList["0"] !== undefined) {
          store.commit("setCurCompany", store.state.company.companyList["0"]);
        }
      }

      that.loading = false;
    })
    .catch((err) => {
      if (err.request.status === 0) {
        that.$toast.add({
          severity: "warn",
          summary: `Нет связи с сервером ${err.request.status}`,
          detail: "Попробуйте позднее!",
          life: 5000,
        });
      } else if (err.request.status == 403) {
        that.$toast.add({
          severity: "warn",
          summary: `Время сессии истекло`,
          detail: "Требуется авторизация",
          life: 5000,
        });
        that.$router.push("/login");
      } else {
        that.$toast.add({
          severity: "error",
          summary: `Неизвестная ошибка ${err.request.status}`,
          detail: "Попробуйте позднее!",
          life: 5000,
        });
      }
    });
}

export function changePassword(that) {
  api
    .put("change-password", {
      new_password: that.psw.new,
      new_password_confirm: that.psw.confirm,
      old_password: that.psw.old,
      tel: that.tel,
    })
    .then((response) => {
      console.log(response);
      console.info(
        `${response.statusText} ${response.status} ${setEmoji("access")}`
      );
      that.$toast.add({
        severity: "success",
        summary: `${response.data.message}`,
        life: 3000,
      });
      that.$toast.add({
        severity: "success",
        summary: `Вы перенаправлены на страницу входа`,
        life: 6000,
      });
      that.updateInputs(that.psw);
      setTimeout(() => {
        that.$router.push("/login");
      }, 5000);
      that.updateInputs(that.psw);
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      // console.log(JSON.parse(err.request.responseText));
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 400:
          let key = Object.keys(JSON.parse(err.request.responseText));
          let msg = JSON.parse(err.request.responseText)[key].join("     ");
          console.log();
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: `${msg}`,
            life: 10000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: `${statusText}`,
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: `${statusText}`,
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}
