<template>
  <div class="mt-2 surface-card p-4 shadow-2 border-round">
    <TabView>
      <TabPanel header="Данные пользователя">
        <data-profile />
      </TabPanel>
      <TabPanel v-badge="'NEW'" header="Смена пароля">
        <template #header>
          <Badge
            style="color: #fff !important; margin-top: -16px;"
            size="small"
            value="NEW"
          ></Badge>
        </template>
        <change-password />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import ChangePassword from "./components/ChangePassword.vue";
import DataProfile from "./components/DataProfile.vue";

export default {
  name: "Profile",
  components: {
    DataProfile,
    ChangePassword,
  },
};
</script>

<style>
</style>