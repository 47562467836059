<template>
  <div class="flex flex-wrap p-0 w-full">
    <div class="w-full lg:w-4 surface-card">
      <form @submit.prevent="change">
        <label for="password1" class="block text-sm text-900 font-medium mb-2"
          >Текущий пароль</label
        >
        <Password
          inputClass="w-full p-2 sm:p-3"
          :feedback="false"
          toggleMask
          id="password1"
          class="w-full mb-4"
          v-model.trim="psw.old"
          minlength="6"
          required
          ref="old"
        />

        <label for="password2" class="block text-900 font-medium mb-2 text-sm"
          >Новый пароль</label
        >
        <Password
          inputClass="w-full p-2 sm:p-3"
          :feedback="true"
          toggleMask
          id="password2"
          class="w-full mb-4"
          v-model.trim="psw.new"
          minlength="6"
          required
        >
          <template #header>
            <h5 class="mb-2">Уровень надежности</h5>
          </template>
          <template #footer>
            <Divider />
            <p class="mt-3 font-bold">Обязательно</p>
            <ul class="pl-2 ml-2" style="line-height: 1.5">
              <li>Минимум 6 символов</li>
              <li>Одна цифра (0-9)</li>
              <li>Одна строчная буква (abc)</li>
              <li>Одна прописная буква (ABC)</li>
              <li>Один спецсимвол (, : ; ! ? ¿ – – — @)</li>
              <li>
                Пароль не должен содержать эти данные:
                <ul class="ml-2">
                  <li>
                    {{ $store.state.curUser.user_name }}
                  </li>
                  <li>
                    {{ $store.state.curUser.email }}
                  </li>
                  <li>
                    {{ $store.state.curUser.phone }}
                  </li>
                </ul>
              </li>
            </ul>
          </template></Password
        >

        <label for="password3" class="block text-900 font-medium mb-2 text-sm"
          >Подтверждение нового пароля</label
        >
        <Password
          inputClass="w-full p-2 sm:p-3"
          :feedback="false"
          toggleMask
          id="password3"
          class="w-full"
          :class="{ 'p-invalid': isValidate }"
          v-model.trim="psw.confirm"
          minlength="6"
          required
          aria-label="Password"
          name="password"
          @paste="notPast($event)"
        />
        <small
          :class="isValidate ? 'opacity-100' : 'opacity-0'"
          class="p-error"
          id="username-help"
          >Пароли не совпадают</small
        >
        <Button
          type="submit"
          label="Сменить"
          icon="pi"
          :disabled="!goodVal"
          :loading="loading"
          class="w-full mt-6 justify-content-center flex"
        ></Button>
      </form>
    </div>
  </div>
</template>
  
  <script>
import { changePassword } from "../api/profile";
import store from "@/store";
export default {
  name: "ChangePassword",
  data() {
    return {
      psw: {
        old: "",
        new: "",
        confirm: "",
      },
      loading: false,
      tel: store.state.curUser.phone,
    };
  },
  methods: {
    change() {
      if (this.goodVal) {
        this.loading = true;
        changePassword(this);
      } else {
        that.$toast.add({
          severity: "warn",
          summary: `Все поля должны быть заполнены`,
          life: 5000,
        });
      }
    },
    updateInputs(obj) {
      [...Object.keys(obj)].forEach((el) => {
        obj[el] = "";
      });
    },
    notPast(event) {
      setTimeout(() => {
        this.psw.confirm = "";
      }, 50);
    },
  },
  computed: {
    isValidate() {
      return this.psw.new !== this.psw.confirm;
    },
    goodVal() {
      return (
        this.psw.new == this.psw.confirm &&
        this.psw.old.length >= 6 &&
        this.psw.new.length >= 6 &&
        this.psw.confirm.length >= 6
      );
    },
  },
  mounted() {
    // console.log(this.$primevue.config.locale);
    // console.log(this.$refs.old.onMaskToggle());
  },
};
</script>
  
  <style>
</style>