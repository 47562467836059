<template>
  <layout-base>
    <loading-progress :loading="loading"></loading-progress>
    <mobile-spiner :loading="loading" />
    <Profile />
    <!-- <div class="mt-2 surface-card p-4 shadow-2 border-round">
      <Avatar
        class="bg-black-alpha-90 text-primary"
        size="xlarge"
        shape="circle"
      >
        {{
          $store.state.curUser
            ? createName($store.state.curUser.user_name)
            : "AA"
        }}
      </Avatar>
      <div class="mt-4 grid grid-nogutter border-top-1 surface-border pt-2">
        <div class="col-12 md:col-6 p-3">
          <div class="text-500 font-medium mb-2">Ф.И.О.</div>
          <div class="text-900">
            {{
              $store.state.curUser && $store.state.curUser.user_name != ""
                ? $store.state.curUser.user_name
                : emptyData
            }}
          </div>
        </div>
        <div class="col-12 md:col-6 p-3">
          <div class="text-500 font-medium mb-2">Email</div>
          <div class="text-900">
            {{
              $store.state.curUser && $store.state.curUser.email != ""
                ? $store.state.curUser.email
                : emptyData
            }}
          </div>
        </div>
        <div class="col-12 md:col-6 p-3">
          <div class="text-500 font-medium mb-2">Роль</div>
          <div class="text-900">
            {{
              $store.state.curUser
                ? config.ROLE[$store.state.curUser.role].name
                : ""
            }}
          </div>
        </div>
        <div class="col-12 md:col-6 p-3">
          <div class="text-500 font-medium mb-2">Телефон</div>
          <div class="text-900">
            {{
              $store.state.curUser && $store.state.curUser.phone != ""
                ? $store.state.curUser.phone
                : emptyData
            }}
          </div>
        </div>
 

        <div class="col-12 p-3">
          <div class="text-500 font-medium mb-3">Документы</div>
          <div
            class="
              flex
              md:align-items-center md:justify-content-between
              border-top-1
              surface-border
              p-3
              flex-column
              md:flex-row
            "
          >
            <div class="flex align-items-center">
              <span class="block pi pi-file mr-2"></span>
              <span class="text-900">Договор фоерты по фирме.pdf</span>
            </div>
            <Button
              icon="pi pi-download"
              label="Скачать"
              class="p-button-outlined mt-2 md:mt-0"
            ></Button>
          </div>
          <div
            class="
              flex
              md:align-items-center md:justify-content-between
              border-top-1 border-bottom-1
              surface-border
              p-3
              flex-column
              md:flex-row
            "
          >
            <div class="flex align-items-center">
              <span class="block pi pi-file mr-2"></span>
              <span class="text-900">Договор на расторжение очка.pdf</span>
            </div>
            <Button
              icon="pi pi-download"
              label="Скачать"
              class="p-button-outlined mt-2 md:mt-0"
            ></Button>
          </div>
        </div>
      </div>
    </div> -->
  </layout-base>
</template>
<script>
import store from "@/store";
import config from "@/configs";
import { getProfile } from "@/api/profile";
import { createProfileName } from "@/handlers/createProfileName";
import Profile from "../modules/profile";
export default {
  components: {
    Profile,
  },
  data() {
    return {
      config: config,
      profiles: null,
      companies: [],
      loading: true,
      emptyData: "Не указано",
    };
  },
  computed: {},
  methods: {
    createName(value) {
      return createProfileName(value);
    },
  },
  mounted() {
    !this.profiles ? getProfile(this) : "";
    window.scrollTo(0, 0);
  },

  created() {
    document.title = "Профиль | " + window.appBaseTitle;
  },
};
</script>

<style lang="scss" scoped>
</style>